import { useState, useEffect } from 'react';
import { useApi } from '../services/ApiProvider';  // Adjust the path if necessary

export const useFundingApplications = () => {
  const api = useApi();  
  const [fundingApplications, setFundingApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFundingApplications = async () => {
    setLoading(true);
    setError(null);
    try {
      const applications = await api.getAllFundingApplications();
      console.log(applications);
      setFundingApplications(applications);
    } catch (e) {
      console.error('Failed to fetch funding applications:', e);
      setError('Failed to load funding applications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFundingApplications();  // Fetch data on component mount
  }, []);

  return { fundingApplications, loading, error, fetchFundingApplications };
};
