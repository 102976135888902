import { useState } from 'react';
import { useApi } from "../services/ApiProvider";

export const useDeleteTicket = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleDeleteTicket = async (rsvpId, attendeeId) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
        console.log(rsvpId);
        console.log(attendeeId);
      const result = await api.deleteTicket(rsvpId, attendeeId);
      console.log(success);
      return result;
    } catch (error) {
        console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { handleDeleteTicket, loading, error, success };
};
