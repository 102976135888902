import { useState } from 'react';
import { useApi } from "../services/ApiProvider";

export const useReservation = () => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const makeReservation = async (reservationData) => {
        setLoading(true);
        setError(null);
        console.log(reservationData);
        try {
            await api.createReservation(reservationData);
        } catch (e) {
            setError(e.message || 'Failed to create reservation');
            throw e;
        } finally {
            setLoading(false);
        }
    };

    return { makeReservation, loading, error };
};
