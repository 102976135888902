import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const handleDashboardClick = () => {
    navigate(`/staff-dashboard`);
  };

  return (
    <header className="flex justify-between items-center bg-gray-800 text-white p-4 rounded-md shadow-lg">
      <h1
        className="text-2xl font-bold cursor-pointer"
        onClick={handleDashboardClick}
      >
        Club Funding Application
      </h1>
    </header>
  );
}

export default Header;
