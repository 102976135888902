import { useState } from 'react';
import { useApi } from "../services/ApiProvider";

export const useDeleteRSVP = (fetchRSVP) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleDeleteRSVP = async (rsvpId) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      await api.deleteRSVP(rsvpId);
      setSuccess('RSVP deleted successfully');
      await fetchRSVP();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { handleDeleteRSVP, loading, error, success };
};
