import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useFundingDetails = (clubId) => {
    const api = useApi();
    const [fundingDetails, setFundingDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFundingDetails = async () => {
        setLoading(true);
        try {
            const data = await api.getFundingApplicationDetails(clubId);
            setFundingDetails(data);
        } catch (err) {
            setError("Failed to fetch funding details.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFundingDetails();
    }, [clubId]);

    return { fundingDetails, loading, error, fetchFundingDetails };
}