import React from "react";
import { useParams } from "react-router-dom";
import { useClubDetails } from "../hooks/useClubDetails";
import { useClubMembers } from "../hooks/useClubMembers";
import ClubEventList from "../components/ClubEventList";
import MemberList from "../components/MemberList";

const StudentClubDashboard = () => {
    const { club_id } = useParams();
    const clubId = parseInt(club_id);
    const { clubDetails, loading, error } = useClubDetails(clubId);
    const { members, memberLoading, memberError, fetchMembers} = useClubMembers(clubId);
    
    if (loading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }


    if (memberLoading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (memberError) {
        return <div className="text-center text-red-500 mt-10">{memberError}</div>;
    }

    return (
        <div className="p-4">
            <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                <div className="flex justify-between mb-4">
                    <h1 className="text-2xl font-bold">Student Club</h1>
                    <button className="bg-sky-500 px-4 py-2 rounded-lg text-white" onClick={() => window.location.href = `/student-club-dashboard/funding/${clubId}`}>
                        View Funding Application
                    </button>
                </div>
                <p className="font-bold">
                    Club Name:{" "}
                    <span className="font-normal">{clubDetails.clubName}</span>
                </p>
                <p className="font-bold">
                    Description:{" "}
                    <span className="font-normal">
                        {clubDetails.clubDescription}
                    </span>
                </p>
                <p className="font-bold">
                    Remaining Funds:{" "}
                    <span className="font-normal">
                        ${clubDetails.clubFunds.toFixed(2)}
                    </span>
                </p>
            </div>

            <div className="flex">
                <ClubEventList clubId={clubId} />
                <MemberList students={members} clubId={clubId} fetchMembers={fetchMembers} />
            </div>
        </div>
    );
};

export default StudentClubDashboard;
