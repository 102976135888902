import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

function Header({}) {
  const {member_id} = useParams();
  const navigate = useNavigate();
  const handleRSVPClick = () => {
    navigate(`/student-member-dashboard/rsvp`);
  };

  const handleDashboardClick = () => {
    navigate(`/student-member-dashboard`);
  };

  return (
    <header className="flex justify-between items-center bg-gray-800 text-white p-4 rounded-md shadow-lg">
      <h1
        className="text-2xl font-bold cursor-pointer"
        onClick={handleDashboardClick}
      >
        Event Dashboard
      </h1>
      <button 
        className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
        onClick={handleRSVPClick}
      >
        My RSVPs
      </button>
    </header>
  );
}

export default Header;
