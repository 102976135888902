import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useAdminedClubs = () => {
    const api = useApi();
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await api.getAdminedClubs();
                setClubs(response);
            } catch (err) {
                setError("Failed to fetch clubs");
            } finally {
                setLoading(false);
            }
        };
        fetchClubs();
    }, []);

    return { clubs, loading, error };
};
