import React from "react";
import PropTypes from "prop-types";
import { useEditMember } from "../hooks/useEditMember";



const EditMemberModal = ({
    isOpen,
    onClose,
    studentId,
    clubId,
    originalRole,
    fetchMembers,
}) => {

    const {
        selectedRole,
        handleRoleChange,
        handleSubmit,
        loading,
        error,
        clearError,
    } = useEditMember(studentId, clubId, originalRole, onClose, fetchMembers);


    if (!isOpen) return null;
    
    
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded-lg shadow-lg w-2/3">
        <h2 className="text-xl font-bold mb-4">Edit Student Role</h2>
        {loading ? (
            <p>Updating role...</p>
        ) : error ? (
            <div className="mb-4 p-2 bg-red-100 text-red-700 rounded flex justify-around">
                <p>{error}</p>
                <button
                    onClick={clearError}
                    className="ml-2 text-red-700 border-red-600"
                >
                    Close
                </button>
            </div>
        ) : (
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block mb-2">Select the Role</label>
                    <div>
                        <label className="inline-flex items-center mr-4">
                            <input
                                type="radio"
                                value="admin"
                                checked={selectedRole === "admin"}
                                onChange={handleRoleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Admin</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                value="member"
                                checked={selectedRole === "member"}
                                onChange={handleRoleChange}
                                className="form-radio"
                            />
                            <span className="ml-2">Member</span>
                        </label>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Update
                    </button>
                </div>
            </form>
        )}

        
    </div>
</div>

    );
}

EditMemberModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    studentId: PropTypes.number.isRequired,
    clubId: PropTypes.number.isRequired,
    originalRole: PropTypes.string.isRequired,
    fetchMembers: PropTypes.func.isRequired,
};

export default EditMemberModal;