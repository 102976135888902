import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useEditEvent = (clubId, eventId, fetchEvents, setEventId, onClose) => {
    const api = useApi();
    const [editEvent, setEditEvent] = useState({
        clubId: clubId,
        event_id: eventId,
        title: "",
        description: "",
        venue: {
            venue_id: "",
            venue_name: "",
            max_capacity: 0
        },
        capacity: 0,
        date: "",
        cost: 0,
        numOfAttendees: 0,
    });
    const [venues, setVenues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!eventId) return;
        setLoading(true);
        const fetchEventAndVenues = async () => {
            try {
                const [eventDetails, venues] = await Promise.all([
                    api.getEventDetails(eventId),
                    api.getVenues()
                ]);
    
                setEditEvent(eventDetails);
                setVenues(venues);
            } catch (err) {
                setError("Failed to fetch event and venues.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchEventAndVenues();
    }, [eventId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditEvent((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleVenueChange = (e) => {
        const selectedVenueId = e.target.value;
        const venue = venues.find((v) => v.venue_id === parseInt(selectedVenueId));

        setEditEvent((prevState) => ({
            ...prevState,
            venue: {
                ...prevState.venue,
                venue_id: selectedVenueId,
            },
            capacity: venue ? venue.max_capacity : 0,
        }));
    };

    const handleUpdateEvent = async () => {
        try {
            await api.updateEvent(clubId, editEvent.id, editEvent);
            onClose();
            await fetchEvents();
        } catch (err) {
            if (err.message === "Conflict") {
                setError("Venue is not available.");
            } else {
                setError("Failed to update event.");
            }
            console.error("Failed to update event", err);
        }
    };

    const handleDeleteEvent = async () => {
        try {
            await api.deleteEvent(clubId, editEvent.id);
            setEventId(null);
            onClose();
            await fetchEvents();
        } catch (err) {
            console.error("Failed to delete event", err);
            setError("Failed to delete event.");
        }
    };

    const clearError = () => {
        setError(null);
    };

    return {
        editEvent,
        venues,
        handleInputChange,
        handleVenueChange,
        handleUpdateEvent,
        handleDeleteEvent,
        loading,
        error,
        clearError,
    };
};
