import { useState } from "react";
import { useApi } from "../services/ApiProvider";

export const useUpdateFunding = () => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateFunding = async (clubId, fundingData) => {
        setLoading(true);
        try {
            await api.updateFundingApplication(clubId, fundingData);
        } catch (err) {
            setError("Failed to update funding application.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, updateFunding };
}