import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useClubDetails = (clubId) => {
    const api = useApi();
    const [clubDetails, setClubDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchClubData = async () => {
            try {
                const clubData = await api.getClubDetails(clubId)
                setClubDetails(clubData);
            } catch (err) {
                setError("Failed to fetch club details");
            } finally {
                setLoading(false);
            }
        };

        if (clubId) {
            fetchClubData();
        }
    }, [clubId]);

    return { clubDetails, loading, error };
};
