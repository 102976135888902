import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useClubEvents = (clubId) => {
    const api = useApi();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchEvents = async () => {
        setLoading(true);
        try {
            const data = await api.getClubEvents(clubId);
            setEvents(data);
        } catch (err) {
            setError("Failed to fetch events.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [clubId]);

    return { events, loading, error, fetchEvents };
};
