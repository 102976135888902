import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useCreateEvent = (clubId, fetchEvents, onClose) => {
    const api = useApi();
    const [newEvent, setNewEvent] = useState({
        club_id: clubId,
        venue_id: "",
        title: "",
        event_description: "",
        event_date: "",
        event_cost: "",
        capacity: "",
    });

    const [venues, setVenues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchVenues = async () => {
            try {
                const data = await api.getVenues();
                setVenues(data);
            } catch (err) {
                setError("Failed to fetch venues.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchVenues();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleVenueChange = (e) => {
        const selectedVenueId = e.target.value;
        const venue = venues.find((v) => v.venue_id === parseInt(selectedVenueId));

        setNewEvent((prevState) => ({
            ...prevState,
            venue_id: selectedVenueId,
            capacity: venue ? venue.max_capacity : 0,
        }));
    };

    const handleSubmitNewEvent = async () => {
        setLoading(true);
        try {
            await api.createEvent(newEvent);
            onClose();
            await fetchEvents();
            
        } catch (err) {
            if (err.message === "Conflict") {
                setError("Venue is not available.");
            } else {
                setError("Failed to create event.");
            }
            console.error("Failed to create event", err);
        } finally {
            setNewEvent({
                club_id: clubId,
                venue_id: null,
                title: "",
                event_description: "",
                event_date: "",
                event_cost: null,
                capacity: null,
            });
            setLoading(false);
        }
    };

    const clearError = () => {
        setError(null);
    };


    return {
        newEvent,
        venues,
        loading,
        error,
        handleInputChange,
        handleVenueChange,
        handleSubmitNewEvent,
        clearError,
    };
};
