import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="p-4">
            <div className="bg-white rounded-lg p-6 mb-6 shadow-lg text-center">
                <h1 className="text-3xl font-bold mb-4">University Student Club Management Solutions</h1>
                <p className="mb-6">
                    Welcome to the University Student Club Management Solutions. This application is designed to help university student clubs manage their events and members. Please log in as an admin or student to access the features.
                </p>
                <div className="flex justify-center space-x-4">
                    <Link
                        to="/student-admin/login"
                        className="bg-sky-400 px-4 py-2 rounded-lg text-white "
                    >
                        Student Admin Login
                    </Link>
                    <Link
                        to="/login"
                        className="bg-sky-400 px-4 py-2 rounded-lg text-white"
                    >
                        Student Login
                    </Link>
                    <Link
                        to="/staff/login"
                        className="bg-sky-400 px-4 py-2 rounded-lg text-white"
                    >
                        Staff Login
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Home;
