import React from "react";

function SearchBar( {searchQuery, setSearchQuery}){
    return (
        <div className="mt-6 flex justify-center">
          <input
            type="text"
            placeholder="Search by event title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} 
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          />
        </div>
    )
}

export default SearchBar;