import { useState } from 'react';
import PropTypes from 'prop-types';
import { useReservation } from '../../hooks/useReservation';
import Notification from './Notification';

const ReservationModal = ({ isOpen, onClose, event }) => {
    const { makeReservation, loading } = useReservation();
    const [emails, setEmails] = useState(['']);
    const [notification, setNotification] = useState('');

    if (!isOpen) return null;

    // Function to handle adding a new email field
    const addEmailField = () => {
        setEmails([...emails, '']);
    };

    // Function to handle removing an email field
    const removeEmailField = (index) => {
        const updatedEmails = emails.filter((_, i) => i !== index);
        setEmails(updatedEmails);
    };

    // Function to handle email input changes
    const handleEmailChange = (index, value) => {
        const updatedEmails = emails.map((email, i) => (i === index ? value : email));
        setEmails(updatedEmails);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Only include non-empty emails
        const reservationData = {
            event_id: event.id,
            emails: emails.filter((email) => email.trim() !== '')
        };

        try {
            await makeReservation(reservationData);
            setNotification({
                message: 'Reservation created successfully!',
                type: 'success'
            });
            setEmails(['']);
        } catch (error) {
            let errorMessage = 'An unexpected error occurred.';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = `Error: ${error.response.data.error}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            setNotification({
                message: errorMessage,
                type: 'error'
            });
        }
    };

    const handleCloseNotification = () => {
        setNotification('');
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
                    <h2 className="text-2xl font-semibold mb-4">Create Reservation</h2>
                    <form onSubmit={handleSubmit}>
                        {emails.map((email, index) => (
                            <div key={index} className="mb-4">
                                <label className="block text-gray-700">
                                    Attendee {index + 1}
                                </label>
                                <div className="flex items-center">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => handleEmailChange(index, e.target.value)}
                                        className="w-full px-3 py-2 border rounded-lg"
                                        placeholder="Enter attendee's student email"
                                    />
                                    {index > 0 && (
                                        <button
                                            type="button"
                                            onClick={() => removeEmailField(index)}
                                            className="ml-2 px-3 py-2 bg-red-500 text-white rounded-lg"
                                        >
                                            -
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addEmailField}
                            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                        >
                            + Add another attendee
                        </button>
                        <button type="submit" className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg">
                            {loading ? 'Submitting...' : 'Submit Reservation'}
                        </button>
                        <button
                            type="button"
                            className="mt-4 ml-4 px-4 py-2 bg-red-500 text-white rounded-lg"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </form>
                    {notification && (
                        <Notification
                            message={notification.message}
                            type={notification.type}
                            onClose={handleCloseNotification}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

ReservationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired
};

export default ReservationModal;