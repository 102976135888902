import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useModal } from "../hooks/useModal";
import EditMemberModal from "../components/EditMemberModel";

const MemberList = ({ students, clubId, fetchMembers }) => {

    const {
        isModalOpen: isEditModalOpen,
        openModal: openEditModal,
        closeModal: closeEditModal,
    } = useModal();

    const [selectedStudentId, setSelectedStudentId] = useState(null)
    const [originalRole, setOriginalRole] = useState(null)

    return (
        <div className="w-1/3 bg-white rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-bold mb-4">Members</h2>
            <table className="w-full">
                <thead className="text-left">
                    <tr>
                        <th className="w-1/3">Username</th>
                        <th className="w-1/3">Role</th>
                        <th className="w-32"></th>
                    </tr>
                </thead>
                <tbody className="divide-y">
                    {students.map((student) => (
                        <tr key={student.studentId} className="h-9">
                            <td>{student.username}</td>
                            <td>{student.role}</td>
                            <td>
                                <button className="bg-sky-500 px-4 py-1 rounded-lg text-white"
                                    onClick={() => {
                                        setSelectedStudentId(student.studentId);
                                        setOriginalRole(student.role);
                                        openEditModal();
                                    }}
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <EditMemberModal
                isOpen={isEditModalOpen}
                onClose={()=>{
                    closeEditModal();
                    setSelectedStudentId(null);
                    setOriginalRole(null);
                }}
                studentId={selectedStudentId}
                clubId={clubId}
                originalRole={originalRole}
                fetchMembers={fetchMembers}
            />
            
        </div>
    );
};

MemberList.propTypes = {
    students: PropTypes.array.isRequired,
    clubId: PropTypes.number.isRequired,
    fetchMembers: PropTypes.func.isRequired,
};


export default MemberList;
