import { useState, useEffect } from 'react';
import { useApi } from "../services/ApiProvider";

export const useEvents = (searchQuery) => {
    const api = useApi();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setData(null);
            try {
                const events = await api.fetchEvents(searchQuery);
                setData(events);
            } catch (e) {
                console.log(e)
                setError('Failed to fetch event data');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [searchQuery]);

    return { data, loading, error };
};
