import React, { useState } from "react";
import PropTypes from "prop-types";

import { useUpdateFunding } from "../hooks/useUpdateFunding";

const UpdateFundingApplication = ({ funding, onUpdate }) => {
    const { loading, error, updateFunding } = useUpdateFunding();
    const [fundingData, setFundingData] = useState({
        applicationSubmissionDate: funding.applicationSubmissionDate,
        applicationStatus: funding.applicationStatus,
        applicationDescription: funding.applicationDescription,
        applicationFunds: funding.applicationFunds,
        club_id: funding.club_id,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFundingData({
            ...fundingData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFunding(funding.club_id, fundingData);
        onUpdate();
        window.location.reload();
    };

    return (
        <div className="p-4">
            <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                <div className="flex justify-between mb-4">
                    <h1 className="text-2xl font-bold">Update Funding Application</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="applicationSubmissionDate"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Date
                        </label>
                        <input
                            type="date"
                            name="applicationSubmissionDate"
                            id="applicationSubmissionDate"
                            value={fundingData.applicationSubmissionDate}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="applicationStatus"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Status
                        </label>
                        <select
                            name="applicationStatus"
                            id="applicationStatus"
                            value={fundingData.applicationStatus.toLowerCase()}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            
                        >
                            <option value="draft">Draft</option>
                            <option value="submitted">Submitted</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="applicationDescription"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Description
                        </label>
                        <textarea
                            name="applicationDescription"
                            id="applicationDescription"
                            value={fundingData.applicationDescription}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"

                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="applicationFunds"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Funds
                        </label>
                        <input
                            type="number"
                            name="applicationFunds"
                            id="applicationFunds"
                            value={fundingData.applicationFunds}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                        Update Funding Application
                    </button>
                </form>
            </div>
        </div>
    );
}

UpdateFundingApplication.propTypes = {
    funding: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default UpdateFundingApplication;
