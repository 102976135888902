import React from 'react';
import { useAuthentication } from '../services/AuthenticationProvider';

function LoggedInUserDetail() {
  const { user, logout } = useAuthentication();

  return (
    user && (
      <div className="p-4">
        <div className="bg-white rounded-lg p-6 shadow-lg text-center flex justify-between">
          <h2 className="text-2xl font-bold mb-4">Hi, {user.username || 'you'}</h2>
          <button
            onClick={
              () => logout()
              }
            className="bg-sky-500 px-4 py-2 rounded-lg text-white hover:bg-sky-600 transition"
          >
            Log out
          </button>
        </div>
      </div>
    )
  );
}

export default LoggedInUserDetail;