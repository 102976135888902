import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReservation } from '../../hooks/useReservation';
import Notification from './Notification';
import ReservationModal from './ReservationModal';

const EventDetailModal = ({ isOpen, onClose, event}) => {
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  if (!isOpen || !event) return null;

  const handleReservationClick = () => {
    setIsReservationModalOpen(true);
  };

  const closeReservationModal = () => {
    setIsReservationModalOpen(false);
  };

  return (
    <>
      {/* Event Detail Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
          <h2 className="text-2xl font-semibold mb-4">{event.title}</h2>
          <p className="text-gray-700 mb-4">{event.description}</p>
          <p className="text-gray-500">Date: {event.date}</p>
          <p className="text-gray-500">Location: {event.venue.venue_name}</p>
          <p className="text-gray-500">Address: {event.venue.venue_address}</p>
          <p className="text-gray-500">Number of attendees: {event.numOfAttendees}</p>
          <button
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="mt-4 ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
            onClick={handleReservationClick}
          >
            Create Reservation
          </button>
        </div>
      </div>

      {/* Reservation Modal */}
      {isReservationModalOpen && (
        <ReservationModal isOpen={isReservationModalOpen} onClose={closeReservationModal} event={event} />
      )}
    </>
  );
};

EventDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  event: PropTypes.object,
};

export default EventDetailModal;