import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";


export const useClubMembers = (club_id) => {
    const api = useApi();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await api.getClubMembers(club_id);
            setMembers(response);
        } catch (err) {
            setError("Failed to fetch club members");
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        if(club_id){
            fetchMembers();
        }
    }, [club_id]);
        

    return { members, loading, error, fetchMembers };
}