import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EventDetailModal from './EventDetailModal';

function EventList({ data, loading }) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  return (
    <div className="mt-6 grid grid-cols-1 gap-4">
      {data && data.length ? (
        data.map((event, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md shadow-md cursor-pointer"
            onClick={() => handleEventClick(event)}
          >
            <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
            <p className="text-gray-700">{event.description}</p>
            <p className="text-gray-700">{event.date}</p>
          </div>
        ))
      ) : (
        !loading && <p className="text-center text-gray-500">No events available</p>
      )}

      <EventDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        event={selectedEvent}
      />
    </div>
  );
}

EventList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default EventList;
