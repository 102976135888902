import React from 'react';
import '../../App.css';
import Header from '../../components/StaffAdmin/Header.jsx';
import FundingList from '../../components/StaffAdmin/FundingList.jsx';
import { useFundingApplications } from '../../hooks/useFundingApplications';  // Adjust the path if necessary

function StaffDashboard() {
  const { fundingApplications, loading, error } = useFundingApplications();  // Use the custom hook

  return (
    <div className="container mx-auto p-4">
      <Header />
      <h1 className="text-2xl font-bold mb-4 text-center">All Funding Applications</h1>
      {loading && <p className="text-center text-xl mt-10">Loading...</p>}
      {error && <p className="text-center text-red-500 mt-10">{error}</p>}
      {!loading && !error && <FundingList data={fundingApplications} />}
    </div>
  );
}

export default StaffDashboard;
