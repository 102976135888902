import { useState } from 'react';
import { useApi } from '../services/ApiProvider'; 

export const useUpdateFundingStatus = () => {
  const api = useApi();  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateFundingStatus = async (clubId, status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.updateFundingStatus(clubId, { status });
      console.log(response);
      return response; 
    } catch (e) {
      console.error('Failed to update funding application status:', e);
      setError('Failed to update status');
    } finally {
      setLoading(false);
    }
  };

  return { updateFundingStatus, loading, error };
};
