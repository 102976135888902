import React from "react";
import PropTypes from "prop-types";
import { useCreateEvent } from "../hooks/useCreateEvent";

const CreateEventModal = ({ isOpen, onClose, clubId, fetchEvents }) => {
    const {
        newEvent,
        venues,
        loading,
        error,
        handleInputChange,
        handleVenueChange,
        handleSubmitNewEvent,
        clearError,
    } = useCreateEvent(clubId, fetchEvents, onClose);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-2/3">
                <h2 className="text-xl font-bold mb-4">Create Event</h2>

                {loading ? (
                    <p>Loading event details...</p>
                ) : error ? (
                    <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                        <p>{error}</p>
                        <button
                            onClick={clearError}
                            className="ml-2 text-red-700 hover:underline"
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <>
                        <label className="block mb-2">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={newEvent.title}
                            onChange={handleInputChange}
                            placeholder="Event Title"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <label className="block mb-2">Description</label>
                        <textarea
                            name="event_description"
                            value={newEvent.event_description}
                            onChange={handleInputChange}
                            placeholder="Event Description"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        {loading ? (
                            <p>Loading venues...</p>
                        ) : error ? (
                            <p className="text-red-500">{error}</p>
                        ) : (
                            <div>
                                {/* Venue Dropdown */}
                                <label className="block mb-2">Venue</label>
                                <select
                                    name="venue_id"
                                    value={newEvent.venue_id}
                                    onChange={handleVenueChange}
                                    className="mb-4 p-2 border rounded w-full"
                                >
                                    <option value="">Select a venue</option>
                                    {venues.map((venue) => (
                                        <option
                                            key={venue.venue_id}
                                            value={venue.venue_id}
                                        >
                                            {venue.venue_name}
                                        </option>
                                    ))}
                                </select>

                                {/* Auto-fill capacity */}
                                <label className="block mb-2">Capacity</label>
                                <input
                                    type="number"
                                    name="capacity"
                                    value={newEvent.capacity || ""}
                                    onChange={handleInputChange}
                                    placeholder="Event Capacity"
                                    className="mb-2 p-2 border rounded w-full"
                                />
                            </div>
                        )}

                        <label className="block mb-2">Event Date</label>
                        <input
                            type="date"
                            name="event_date"
                            value={newEvent.event_date}
                            onChange={handleInputChange}
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <label className="block mb-2">Event Cost</label>
                        <input
                            type="number"
                            name="event_cost"
                            value={newEvent.event_cost}
                            onChange={handleInputChange}
                            placeholder="Event Cost"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <div className="flex justify-end mt-4">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={handleSubmitNewEvent}
                            >
                                Create Event
                            </button>
                            <button
                                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

CreateEventModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    clubId: PropTypes.number.isRequired,
    fetchEvents: PropTypes.func.isRequired,
};

export default CreateEventModal;
