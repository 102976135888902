import React from "react";
import ClubList from "../components/ClubList";
import { useAdminedClubs } from "../hooks/useAdminedClubs";

const StudentAdminDashboard = () => {
    const { clubs, loading, error } = useAdminedClubs();

    if (loading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }

    return (
        <div className="p-6">
            <h1 className="text-3xl font-bold mb-4 text-center">
                Your Admined Student Clubs
            </h1>
            <ClubList clubs={clubs} />
        </div>
    );
};

export default StudentAdminDashboard;
