import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Notification from '../student/Notification';
import { useUpdateFundingStatus } from '../../hooks/useUpdateFundingStatus';

const FundingDetailModal = ({ isOpen, onClose, application }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [notification, setNotification] = useState('');
  
  // Use the custom hook
  const { updateFundingStatus, loading, error } = useUpdateFundingStatus();

  if (!isOpen || !application) return null;

  const handleOptionClick = async (status) => {
    setIsDropdownOpen(false); 
    
    try {
      await updateFundingStatus(application.club_id, status);
      setNotification({
        message: `Application marked as ${status} successfully!`,
        type: 'success',
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      setNotification({
        message: err || 'Failed to update application status.',
        type: 'error',
      });
    }
  };

  const handleCloseNotification = () => {
    setNotification('');
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
          <h2 className="text-2xl font-semibold mb-4">{application.clubName}</h2>
          <p className="text-gray-700 mb-4">{application.applicationDescription}</p>
          <p className="text-gray-500">Status: {application.applicationStatus}</p>
          <p className="text-gray-500">Submission Date: {application.applicationSubmissionDate}</p>
          <p className="text-gray-500">Requested Amount: ${application.applicationFunds}</p>

          <div className="flex justify-between items-center mt-6">
            {/* Change Status Button on the Bottom Left */}
            <div className="relative">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Change Status
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-40 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOptionClick('Review')}
                    >
                      Review
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOptionClick('Approved')}
                    >
                      Approve
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOptionClick('Rejected')}
                    >
                      Reject
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <button
              className="px-4 py-2 bg-red-500 text-white rounded-lg"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
      )}
    </>
  );
};

FundingDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  application: PropTypes.shape({
    clubName: PropTypes.string.isRequired,
    applicationStatus: PropTypes.string.isRequired,
    applicationSubmissionDate: PropTypes.string.isRequired,
    applicationDescription: PropTypes.string.isRequired,
    applicationFunds: PropTypes.number.isRequired,
    club_id: PropTypes.number.isRequired, 
  }).isRequired,
};

export default FundingDetailModal;
