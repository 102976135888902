import React from 'react';
import Header from '../../components/student/Header';
import RSVPEventCard from '../../components/student/RSVPEventCard';
import { useRsvp } from '../../hooks/useShowRsvp';

function MyRSVPPage() {
  const { data, loading, error, fetchRSVP } = useRsvp(); 

  return (
    <div className="container mx-auto p-4">
      <Header onRSVPClick={() => {}} />
      {loading && <div className="text-center text-xl mt-10">Loading...</div>}
      {error && <div className="text-center text-red-500 mt-10">{error}</div>}
      <div className="mt-6 grid grid-cols-1 gap-4">
        {data && data.length ? (
          data.map((rsvp, index) => (
            <RSVPEventCard 
              key={index} 
              rsvp={rsvp}
              fetchRSVP={fetchRSVP} 
            />
          ))
        ) : (
          !loading && <p className="text-center text-gray-500">No RSVPs available</p>
        )}
      </div>
    </div>
  );
}

export default MyRSVPPage;
