import React from 'react';
import PropTypes from 'prop-types';

const Notification = ({ message, onClose }) => {
    if (!message) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-1/3">
                <h2 className="text-xl font-semibold mb-4">Notification</h2>
                <p className="text-gray-700 mb-4">{message}</p>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

Notification.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default Notification;
