import React, { useState, useEffect } from 'react';
import { useDeleteRSVP } from '../../hooks/useDeleteRsvp';
import { useDeleteTicket } from '../../hooks/useDeleteTicket';

function RSVPEventCard({ rsvp, fetchRSVP }) {
  const [showEmails, setShowEmails] = useState(false);
  const [attendees, setAttendees] = useState([]);

  const { handleDeleteRSVP, loading, error, success } = useDeleteRSVP(fetchRSVP);
  const { handleDeleteTicket, loadingTicket, errorTicket, successTicket } = useDeleteTicket();


  useEffect(() => {
    if (rsvp && rsvp['attendees']) {
      setAttendees(rsvp['attendees']); 
    }
  }, [rsvp]);

  // useEffect(() => {
  //   if (success) {
  //     fetchRSVP();
  //   }else{
  //     console.log(error);
  //   }
  // }, [success, fetchRSVP]); 


  const handleRSVPClick = () => {
    setShowEmails(!showEmails);
  };

  // Remove an attendee from the list
  const handleRemoveAttendee = async (attendeeId) => {
    try {
      await handleDeleteTicket(rsvp.rsvp_id, attendeeId);
      setAttendees(attendees.filter((attendee) => attendee.student_id !== attendeeId));
    } catch (error) {
      console.log(error);
    }
  };

  // Call handleDeleteRSVP function when deleting an RSVP
  const onDeleteRSVP = () => {
    console.log("Tech Hackathon");
    console.log(rsvp.rsvp_id);
    handleDeleteRSVP(rsvp.rsvp_id);
  };

  return (
    <div className="relative bg-white p-4 rounded-md shadow-md mb-4">
      {/* Event Information */}
      <h2 className="text-xl font-semibold mb-2">{rsvp['event title']}</h2>
      <p className="text-gray-700 mb-2"><strong>Date:</strong> {rsvp['event date']}</p>
      <p className="text-gray-700 mb-2"><strong>Venue:</strong> {rsvp['event venue']}</p>

      <button
        className="absolute top-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-3 rounded-lg transition duration-300"
        onClick={handleRSVPClick}
      >
        {showEmails ? 'Hide Attendees' : 'Show Attendees'}
      </button>

      {showEmails && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Attendees:</h3>
          {attendees.map((attendee, index) => (
            <div key={index} className="flex items-center justify-between mb-2">
              <p className="text-gray-700">{attendee.email} </p>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-semibold py-1 px-2 rounded-lg transition duration-300"
                onClick={() => handleRemoveAttendee(attendee.student_id)}
              >
                &minus;
              </button>
            </div>
          ))}

          <button
            className="mt-4 px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-lg"
            onClick={onDeleteRSVP}
          >
            {loading ? 'Deleting...' : 'Delete Entire RSVP'}
          </button>

          {error && <p className="text-red-500 mt-2">{error}</p>}
          {success && <p className="text-green-500 mt-2">{success}</p>}
        </div>
      )}
    </div>
  );
}

export default RSVPEventCard;
