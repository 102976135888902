import React, { useState } from "react";
import PropTypes from "prop-types";
import { useClubEvents } from "../hooks/useClubEvents";
import { useModal } from "../hooks/useModal";
import CreateEventModal from "./CreateEventModal";
import EditEventModal from "./EditEventModal";

const ClubEventList = ({ clubId }) => {
    const { events, loading, error, fetchEvents } = useClubEvents(clubId);
    const {
        isModalOpen: isCreateModalOpen,
        openModal: openCreateModal,
        closeModal: closeCreateModal,
    } = useModal();
    const {
        isModalOpen: isEditModalOpen,
        openModal: openEditModal,
        closeModal: closeEditModal,
    } = useModal();
    
    const [selectedEventId, setSelectedEventId] = useState(null);

    if (loading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }

    return (
        <div className="w-2/3 bg-white rounded-lg p-6 mr-4 shadow-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Events</h2>
                <button
                    className="bg-green-500 px-4 py-2 rounded-lg text-white"
                    onClick={openCreateModal}
                >
                    Create Event
                </button>
            </div>

            {/* List of Events */}
            {events.length > 0 ? (
                events.map((event) => (
                    <div
                        key={event.id}
                        className="mb-4 flex justify-between items-center"
                    >
                        <div>
                            <h3 className="text-lg font-semibold">
                                {event.title}
                            </h3>
                            <p>{event.description}</p>
                        </div>
                        <button
                            className="bg-sky-500 px-4 py-2 rounded-lg text-white"
                            onClick={() => {
                                setSelectedEventId(event.id);
                                openEditModal();
                            }}
                        >
                            Edit
                        </button>
                    </div>
                ))
            ) : (
                <p>No events available.</p>
            )}

            <CreateEventModal
                isOpen={isCreateModalOpen}
                onClose={closeCreateModal}
                clubId={clubId}
                fetchEvents={fetchEvents}
            />
            <EditEventModal
                clubId={clubId}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
                eventId={selectedEventId}
                setEventId={setSelectedEventId}
                fetchEvents={fetchEvents}
            />
        </div>
    );
};

ClubEventList.propTypes = {
    clubId: PropTypes.number.isRequired,
};

export default ClubEventList;
