// useEditMember.js
import { useState } from "react";
import { useApi } from "../services/ApiProvider";

export const useEditMember = (studentId, clubId, originalRole, onClose, fetchMembers) => {
    const api = useApi();

    const [selectedRole, setSelectedRole] = useState(originalRole);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if(originalRole === selectedRole){
                setError("Same Role as before!");
                return;
            }
            if (selectedRole === "admin") {
                await api.addToAdmin(clubId, studentId);
            } else {
                await api.removeFromAdmin(clubId, studentId);
            }
            
            if (fetchMembers) {
                await fetchMembers();
            }
            onClose();
            setSelectedRole(null);
        } catch (err) {
            console.error("Failed to update member role", err);
            setError("Failed to update member role.");
        } finally {
            setLoading(false);
        }
    };

    const clearError = () => {
        setError(null);
    };

    return {
        selectedRole,
        handleRoleChange,
        handleSubmit,
        loading,
        error,
        clearError,
    };
};
