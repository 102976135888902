import React from "react";
import PropTypes from "prop-types";

const FundingApplicationDetails = ({ fundingDetails }) => {
    return (
        <div className="p-4">
            <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                <div className="flex justify-between mb-4">
                    <h1 className="text-2xl font-bold">Details</h1>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="submission_date"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Application Date
                    </label>
                    <p>{fundingDetails.applicationSubmissionDate}</p>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="application_status"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Application Status
                    </label>
                    <p>{fundingDetails.applicationStatus}</p>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="application_description"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Application Description
                    </label>
                    <p>{fundingDetails.applicationDescription}</p>
                </div>
                <div className="mb-4">
                    <label
                        htmlFor="application_funds"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Application Funds
                    </label>
                    <p>{fundingDetails.applicationFunds}</p>
                </div>
            </div>
        </div>
    );
}

FundingApplicationDetails.propTypes = {
    fundingDetails: PropTypes.object.isRequired,
};

export default FundingApplicationDetails;