import { useState, useEffect } from 'react';
import { useApi } from "../services/ApiProvider";

export const useRsvp = () => {
    const api = useApi();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Define fetchRSVP as a function that can be called externally
    const fetchRSVP = async () => {
        setLoading(true);
        setData(null);
        try {
            const rsvps = await api.getMemberRsvp();
            setData(rsvps);
        } catch (e) {
            console.log(e);
            setError('Failed to fetch RSVP data');
        } finally {
            setLoading(false);
        }
    };

    // Call fetchRSVP on component mount
    useEffect(() => {
        fetchRSVP();
    }, []);

    return { data, loading, error, fetchRSVP }; 
};
