import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FundingDetailModal from './FundingDetailModal';


function FundingList({ data }) {
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFundingClick = (application) => {
    setSelectedApplication(application);
    setIsModalOpen(true);
  }


  return (
    <div className="mt-6 grid grid-cols-1 gap-4">
      {data && data.length ? (
        data.map((application, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md shadow-md"
            onClick={() => handleFundingClick(application)}
          >
            <h2 className="text-xl font-semibold mb-2">{application.clubName}</h2>
            <p className={`text-${application.applicationStatus === 'APPROVED' ? 'green' : application.applicationStatus === 'REJECTED' ? 'red' : 'yellow'}-500`}>
              Status: {application.applicationStatus}
            </p>
            <p className="text-gray-700">Submission Date: {application.applicationSubmissionDate}</p>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">No funding applications available</p>
      )}
      <FundingDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        application={selectedApplication}
      ></FundingDetailModal>
    </div>
    
  );
}

FundingList.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default FundingList;
