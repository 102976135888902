import { useState, useEffect } from "react";
import { useApi } from "../services/ApiProvider";

export const useCreateFunding = () => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const createFunding = async (fundingData) => {
        setLoading(true);
        try {
            await api.createFundingApplication(fundingData);
        } catch (err) {
            setError("Failed to create funding application.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, createFunding };
}