import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFundingDetails } from "../hooks/useFundingDetails";
import FundingApplicationDetails from "../components/FundingApplicationDetails";
import UpdateFundingApplication from "../components/UpdateFundingApplication";
import { useCancelFunding } from "../hooks/useCancelFunding";
import {Link} from "react-router-dom";

const StudentClubFunding = () => {
    const { club_id } = useParams();
    const clubId = parseInt(club_id);
    const { fundingDetails, loading, error } = useFundingDetails(clubId);
    const { cancel_loading, cancel_error, cancelFunding } = useCancelFunding();
    const [isEditing, setIsEditing] = useState(false);
    const cancelData ={
        applicationSubmissionDate:  fundingDetails.applicationSubmissionDate,
        applicationStatus: "DRAFT",
        applicationDescription: fundingDetails.applicationDescription,
        applicationFunds: fundingDetails.applicationFunds,
        club_id: fundingDetails.club_id
    }


    if (loading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleUpdate = () => {
        setIsEditing(false);
    };

    const handleCancel = async () => {
        cancelFunding(clubId, cancelData);
        window.location.reload();
    };


    if (fundingDetails.club_id === clubId) {
        if (isEditing) {
            return (
                <>
                    <div className="p-4">
                        <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                            <div className="flex justify-between mb-4">
                                <h1 className="text-2xl font-bold">Funding Application for this semester</h1>
                            </div>
                            <UpdateFundingApplication funding={fundingDetails} onUpdate={handleUpdate} />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="p-4">
                        <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                            <div className="flex justify-between mb-4">
                                <h1 className="text-2xl font-bold">Funding Application for this semester</h1>
                            </div>
                            <FundingApplicationDetails fundingDetails={fundingDetails} />


                            {/* make 2 button to handle edit and cancel the funding application */}
                            <div className="flex justify-end">
                                {
                                    (fundingDetails.applicationStatus == "DRAFT" ||
                                    fundingDetails.applicationStatus == "SUBMITTED") &&
                                <button
                                    className="bg-blue-500 mr-4 px-4 py-2 rounded-lg text-white"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>
                                }

                                {(fundingDetails.applicationStatus == "SUBMITTED" ||
                                fundingDetails.applicationStatus == "REVIEW") &&
                                    <button
                                    className="bg-red-500 mr-4 px-4 py-2 rounded-lg text-white"
                                    onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    } else {
        return (
            <>  
                <div className="p-4">
                    <div className="bg-white rounded-lg p-6 mb-6 shadow-lg flex justify-center">
                        <Link
                            to={`/student-club-dashboard/funding/${clubId}/create`}
                            className="bg-green-500 px-4 py-2 rounded-lg text-white"
                        >
                            Create FundingApplication
                        </Link>
                    </div>
                </div>
                
                
            </>
        );
    }
};

export default StudentClubFunding;