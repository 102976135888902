import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ClubList = ({ clubs }) => (
    <ul className="space-y-4">
        {clubs.map((club) => (
            <li
                key={club.clubId}
                className="p-4 bg-white shadow-lg rounded-lg hover:bg-gray-100 transition-all"
            >
                <h3 className="text-2xl font-semibold">
                    <Link
                        to={`/student-club-dashboard/${club.clubId}`}
                        className="text-blue-500 hover:underline"
                    >
                        {club.clubName}
                    </Link>
                </h3>
                <p className="text-gray-600 mt-2">{club.clubDescription}</p>
            </li>
        ))}
    </ul>
);

ClubList.propTypes = {
    clubs: PropTypes.arrayOf(
        PropTypes.shape({
            clubId: PropTypes.number.isRequired,
            clubName: PropTypes.string.isRequired,
            clubDescription: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ClubList;
