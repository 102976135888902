import React from "react";
import PropTypes from "prop-types";
import { useEditEvent } from "../hooks/useEditEvent";

const EditEventModal = ({
    clubId,
    isOpen,
    onClose,
    eventId,
    setEventId,
    fetchEvents,
}) => {
    const {
        editEvent,
        venues,
        handleInputChange,
        handleVenueChange,
        handleUpdateEvent,
        handleDeleteEvent,
        loading,
        error,
        clearError,
    } = useEditEvent(clubId, eventId, fetchEvents, setEventId, onClose);

    if (!isOpen) return null;

    const venueId = editEvent.venue ? editEvent.venue.venue_id : "";

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-2/3">
                <h2 className="text-xl font-bold mb-4">Edit Event</h2>

                {loading ? (
                    <p>Loading event details...</p>
                ) : error ? (
                    <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                        <p>{error}</p>
                        <button
                            onClick={clearError}
                            className="ml-2 text-red-700 hover:underline"
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <>
                        <label className="block mb-2">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={editEvent.title}
                            onChange={handleInputChange}
                            placeholder="Event Title"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <label className="block mb-2">Description</label>
                        <textarea
                            name="description"
                            value={editEvent.description}
                            onChange={handleInputChange}
                            placeholder="Event Description"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        {loading ? (
                            <p>Loading venues...</p>
                        ) : error ? (
                            <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                                <p>{error}</p>
                                <button
                                    onClick={clearError}
                                    className="ml-2 text-red-700 hover:underline"
                                >
                                    Close
                                </button>
                            </div>
                        ) : (
                            <>
                                <label className="block mb-2">Venue</label>
                                <select
                                    name="venue_id"
                                    value={venueId}
                                    onChange={handleVenueChange}
                                    className="mb-4 p-2 border rounded w-full"
                                >
                                    <option value="">Select a venue</option>
                                    {venues.map((venue) => (
                                        <option
                                            key={venue.venue_id}
                                            value={venue.venue_id}
                                        >
                                            {venue.venue_name}
                                        </option>
                                    ))}
                                </select>

                                <label className="block mb-2">Capacity</label>
                                <input
                                    type="number"
                                    name="capacity"
                                    value={editEvent.capacity}
                                    onChange={handleInputChange}
                                    placeholder="Event Capacity"
                                    className="mb-2 p-2 border rounded w-full"
                                />
                            </>
                        )}

                        <label className="block mb-2">Event Date</label>
                        <input
                            type="date"
                            name="date"
                            value={editEvent.date}
                            onChange={handleInputChange}
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <label className="block mb-2">Event Cost</label>
                        <input
                            type="number"
                            name="cost"
                            value={editEvent.cost}
                            step="0.01"
                            onChange={handleInputChange}
                            placeholder="Event Cost"
                            className="mb-2 p-2 border rounded w-full"
                        />

                        <label className="block mb-2">Number of Attendees</label>
                        <input
                            type="number"
                            name="numOfAttendees"
                            value={editEvent.numOfAttendees}
                            onChange={handleInputChange}
                            placeholder="Number of Attendeest"
                            className="mb-2 p-2 border rounded w-full"
                            readOnly
                        />

                        <div className="flex justify-between items-center mt-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={handleDeleteEvent}
                            >
                                Delete
                            </button>

                            <div className="flex space-x-2">
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded"
                                    onClick={onClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={handleUpdateEvent}
                                >
                                    Update Event
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

EditEventModal.propTypes = {
    clubId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    eventId: PropTypes.number,
    setEventId: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func.isRequired,
};

export default EditEventModal;
