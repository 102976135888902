import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import '../../App.css';
import Header from '../../components/student/Header.jsx';
import SearchBar from '../../components/student/SearchBar.jsx';
import EventList from '../../components/student/EventList.jsx';
import { useEvents } from '../../hooks/useEvents.js';  

function StudentDashboard() {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { data, loading, error } = useEvents(searchQuery);  

    if (loading) {
      return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (error) {
      return <div className="text-center text-red-500 mt-10">{error}</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <Header />
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            {loading && <p className="mt-4 text-center">Loading...</p>}
            {error && <p className="mt-4 text-center text-red-500">{error}</p>}
            <EventList data={data} loading={loading} />
        </div>
    );
}

export default StudentDashboard;
